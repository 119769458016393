.contracts-overview-title {
  height: 10%;
}
.contracts-date-container {
  display: flex;
  .contracts-overview-left {
    flex-grow: 1;
    padding: 10px 25px 0px 0px;

    .contracts-numbers-container {
      display: flex;
      justify-content: space-between;
      align-items: baseline;

      .overview-contracts-number {
        font-size: 30px;
      }
    }
  }

  .contracts-overview-right {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    .dashboard-link {
      border: none;
      background: none;
      font-size: 14px;
      color: #b8dbc7;
      cursor: pointer;
      width: fit-content;
      padding-top: 8px;
    }

    @media only screen and (max-width: 1000px) {
      .dashboard-link {
        display: none;
      }
    }

    .dashboard-button {
      background-color: #dbede3;
    }
  }
}

.dashboard-link-employee {
  border: none;
  background: none;
  font-size: 14px;
  color: #008c15;
  cursor: pointer;
  width: fit-content;
  padding-top: 8px;
}

.employee-left {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  .dashboard-link-employee {
    border: none;
    background: none;
    font-size: 14px;
    color: white;
    cursor: pointer;
    width: fit-content;
    padding-top: 8px;
  }

  .dashboard-button-employee {
    background-color: white;

    &:hover {
      background-color: white;
    }
  }
}

.dashboard-date-container {
  display: flex;
  padding: 10px;
  justify-content: center;

  .day-of-the-week {
    padding-right: 6px;
    font-weight: bold;
  }
}

.dashboard-employee-numbers-container {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  font-weight: 600;

  .dashboard-employee-number {
    font-size: 30px;
    font-weight: 400;
    display: flex;
    justify-content: center;
  }
}

.container-for-mobile-employee-button {
  .mobile-button-employee {
    &.MuiButton-root {
      background-color: white;
    }

    &:hover {
      background-color: white;
    }
  }
}

.contracts-graph {
  .recharts-layer.recharts-scatter-symbol:first-child,
  .recharts-layer.recharts-scatter-symbol:last-child {
    display: none;
  }

  .recharts-layer.recharts-label-list text:first-child,
  .recharts-layer.recharts-label-list text:last-child {
    display: none;
  }
}

.dashboard-for-you {
  padding-bottom: 10px;
  color: '#9EA2A2';
}

@media only screen and (max-width: 767px) {
  .dashboard-for-you {
    padding-top: 1rem;
  }
}
