.datepicker--white-background {
  .MuiFormControl-root.MuiTextField-root {
    background-color: #fff;
  }
}

.react-datepicker-ignore-onclickoutside {
  height: 50px;
}

.up-date-range-picker {
  height: 49px;
  font-family: 'Montserrat', sans-serif;
  border-radius: 5px;
  outline: none;
}

.react-datepicker__month-container {
  font-family: 'Montserrat', sans-serif;
}

.react-datepicker__day.react-datepicker__day--in-range,
.react-datepicker__day--in-selecting-range {
  background-color: #71b790 !important;
}

.react-datepicker__day.react-datepicker__day--in-range:hover,
.react-datepicker__close-icon::after,
.react-datepicker__day--today.react-datepicker__day--keyboard-selected {
  background-color: #008c15 !important;
}

.MuiPickersDay-root.Mui-selected {
  background-color: #008c15 !important;
}

.react-datepicker__input-container input {
  width: 100%;
}
