.contracts-action-button {
  &.MuiButton-root {
    height: 48px;
  }
}

.floatBottom {
  position: fixed;
  bottom: 60px;
  left: 10px;
  text-align: center;
  box-shadow: 2px 2px 3px #999;
}
