.contracts-number {
  padding: 0 8px 0 20px;
}

.clear-all {
  padding-right: 20px;
  color: #008c15;
  font-weight: bold;
  cursor: pointer;
  border: none;
  background-color: #dbede3;
  font-size: 14px;
  font-family: Montserrat, sans-serif;
}

.icons-container {
  display: flex;

  .icon-container {
    display: flex;
    padding-right: 38px;
    align-items: center;
    cursor: pointer;
    border: none;
    background-color: #dbede3;

    .icon-content {
      color: #008c15;
      font-weight: bold;
      padding-left: 10px;
      cursor: pointer;
      font-size: 14px;
      font-family: Montserrat, sans-serif;
    }
  }
}
