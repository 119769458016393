/* width */
::-webkit-scrollbar {
  width: 20px;
}
/* Track */
::-webkit-scrollbar-track {
 background: transparent; 

} 
/* Handle */
::-webkit-scrollbar-thumb {
  background-color: #CFD0D0; 
  background-clip: padding-box;
  border: 6px solid transparent;
  border-radius:10px;
  &:hover {
    background-color: #9EA2A2; 
  }

}